<template>
  <div class="container">
    <div v-show="alertMessage" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="alertMessage = ''"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i>
      {{alertMessage}}
    </div>
    <div class="page-header d-flex justify-content-between">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} coupons</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">
        Found {{meta.total}} coupons that match '{{query}}'</span>
      <div class="d-flex gap-8">
        <button
          v-if="can(uiPermissions.COUPONS_UPLOAD)"
          class="btn btn-success position-relative"
          @click="showUploadModal = true">
          <i v-if="uploading" class="fe fe-loader spin-icon"></i>
          <i v-else class="fe fe-upload-cloud"/>
          Upload
        </button>
        <button
          v-if="can(uiPermissions.COUPONS_EXPORT)"
          class="btn btn-primary"
          :disabled="exporting"
          @click="handleExport">
          <i v-if="exporting" class="fe fe-loader spin-icon"></i>
          <i v-else class="fe fe-download"/>
          Export
        </button>
        <router-link
          v-if="can(uiPermissions.COUPONS_CREATE)"
          to="/coupons/new"
          data-test="btn-new-coupon"
          class="btn btn-primary ml-auto">
          New coupon
        </router-link>
      </div>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table
              data-test="table-coupons"
              class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>Coupon</th>
                  <th class="text-center">Amount</th>
                  <th>Expires at</th>
                  <th class="text-center">Used</th>
                  <th>Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.code)});`"></span>
                  </td>
                  <td>
                    <div>
                      <router-link v-if="can(uiPermissions.COUPONS_VIEW)" :to="`/coupons/${item.id}`">
                        {{item.code}}
                      </router-link>
                      <span v-else>{{item.code}}</span>
                    </div>
                    <div class="small text-muted">{{truncate(item.description)}}</div>
                  </td>
                  <td v-if="item.type === CouponTypes.Fixed" class="text-center">
                    AED
                    {{numeral(item.amount).format('0,0[.]00')}}
                  </td>
                  <td v-else class="text-center">
                    {{numeral(item.amount / 100).format('%')}}
                  </td>
                  <td>
                    {{item.expiry ? moment(item.expiry).format('dddd, D MMM YYYY') : '-'}}
                  </td>
                  <td class="text-center">
                    <i
                      v-if="item.used"
                      v-b-tooltip
                      title="Used"
                      class="fe fe-check text-success"/>
                    <i
                      v-else
                      v-b-tooltip
                      title="Not used"
                      class="fe fe-x text-danger"/>
                  </td>
                  <td>
                    {{moment.utc(item.updatedAt).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <div
                      v-if="can([uiPermissions.COUPONS_VIEW, uiPermissions.COUPONS_DELETE])"
                      class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        data-test="link-dropdown"
                        class="icon"><i
                          class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.COUPONS_VIEW)"
                          :to="`/coupons/${item.id}`"
                          data-test="link-edit"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                        <div v-if="can(uiPermissions.COUPONS_DELETE)" class="dropdown-divider"></div>
                        <button
                          v-if="can(uiPermissions.COUPONS_DELETE)"
                          type="button"
                          data-test="btn-delete"
                          class="dropdown-item"
                          @click="handleDelete(item)">
                          <i class="dropdown-icon fe fe-trash"></i> Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="9">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>

    <confirm-modal
      :show="showUploadModal"
      :hasFileInput="true"
      :submitting="uploading"
      @confirm="file => handleUpload(file)"
      @close="showUploadModal = false"
      @show="value => showUploadModal = value">
    </confirm-modal>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import download from '@/mixins/download';
import list from '@/mixins/list';
import {coupons} from '@/services';
import ConfirmModal from '@/views/actions/modals/ConfirmModal.vue';
import CouponTypes from '@hellochef/shared-js/enums/CouponTypes';
import submitting from '@hellochef/shared-js/mixins/submitting';
import {captureException} from '@sentry/vue';

export default {
  components: {
    Pagination,
    ConfirmModal,
  },
  mixins: [
    list,
    download,
    submitting,
  ],
  data() {
    return {
      CouponTypes,
      showUploadModal: false,
      uploading: false,
      exporting: false,
      alertMessage: '',
    };
  },
  methods: {
    fetchData(page) {
      return coupons.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting a coupon cannot be undone. Are you sure that you want to delete this coupon?')) {
        await coupons.deleteById(item.id);
        this.refresh();
      }
    },
    handleExport() {
      this.submitIt(async () => {
        this.exporting = true;
        try {
          this.download(await coupons.export());
        }
        catch (error) {
          captureException(error);
          this.$toasted.error('An error occurred while exporting the coupons. Please try again.');
        }
        finally {
          this.exporting = false;
        }
      });
    },
    handleUpload(file) {
      this.alertMessage = '';

      this.submitIt(async () => {
        if (file.name === undefined) {
          this.$toasted.error('Please select a file to upload.');
          return;
        }

        try {
          this.uploading = true;
          const {data} = await coupons.uploadFile(file);

          this.alertMessage = data.message;
          this.showUploadModal = false;
          this.refresh();
        }
        catch (error) {
          captureException(error);
          if (error.response?.status === 422 && error.response?.data.message) {
            this.$toasted.error(error.response.data.message);
            return;
          }

          this.$toasted.error('An error occurred while uploading the file. Please try again.');
        }
        finally {
          this.uploading = false;
        }
      });
    },
  },
};

</script>
