export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showModal: {
      get() { return this.show; },
      set(newValue) {
        this.$emit('show', newValue);
      },
    },
  },
};
